<template>
    <!-- 导航 -->
    <page-head title="AC报告"/>
    <content-bg>
        <template v-slot:content>
            <data-report-structure>
                <template v-slot:left v-if="pageType === 'player'">
                    <select-style :list="userTeamList" label="name" key="id" v-model:value="teamId"
                                  @changeFn="changeFn"></select-style>
                </template>
                <template v-slot:center>{{ playerName }}</template>
                <template v-slot:right>{{ $moment().format('YYYY') }} 01/01 - {{ $moment().format('MM/DD') }}</template>
                <template v-slot:content>
                    <div class="barBox" v-for="(item, index) in weekData" v-if="filterArr.length && weekData.length">
                        <div class="title">
                            <ul class="label">
                                <li v-for="item in colors">
                                    <i :style="{background: item.color}"></i>
                                    <span>{{ item.value }}</span>
                                </li>
                            </ul>
                        </div>
                        <div :key="index" class="bar-item" :id="'bar-item'+index"></div>
                    </div>
                    <no-data v-else></no-data>
                </template>
            </data-report-structure>
        </template>
    </content-bg>
</template>

<script>
import {useRoute} from "vue-router";
import {getCurrentInstance, reactive, toRefs} from "vue";
import {useStore} from "vuex";
import barChart from '../components/bar-chart'

export default {
    name: "details",
    components: {
        barChart
    },
    setup() {
        const route = useRoute()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({
            playerName: route.query.name,
            pageType: route.query.pageType,
            filterArr: [],

            GPSData: [],
            weekData: [],

            userTeamList: [],
            teamId: {},
            nowWeek: 0,
            weekLength: 4, // 前四周

            colors: [
                {color: "#FF5630", value: ">1.5"},
                {color: "#FFC700", value: "1.3-1.5"},
                {color: "#00D190", value: "0.8-1.3"},
                {color: "#FF5630", value: "<0.8"},
            ],
        });


        //  获取gps 筛选项
        const getFilter = () => {
            const {id} = store.state.teamData;
            let data = {
                teamId: id
            }
            proxy.$server.getGpsFilter(data).then(res => {
                if (res.code === 200) {
                    if (res.data && res.data.length) {
                        let arr = [];
                        res.data.map(item => {
                            if (item.ac) {
                                arr.push(item)
                            }

                        })
                        state.filterArr = arr;
                        getData()
                    }
                }
            })
        }

        const getNowWeek = () => {
            proxy.$server.nowWeek().then(res => {
                state.nowWeek = res.data;
            })
        }
        getNowWeek()

        const getData = () => {
            const {id} = store.state.teamData;
            let data = {
                playerId: route.query.id,
                // playerId: '6100f3c24b2c714800ef6f8d',
                teamId: id,
                startDate: proxy.$Config.currentYearFirstDay,
                endDate: proxy.$moment().format('YYYY/MM/DD')
            }
            proxy.$server.getACReport(data).then(res => {
                if (!res.data || !res.data.length) {
                    return
                }

                state.GPSData = res.data || []
                state.weekData = []

                state.weekData = state.GPSData.map((item, index) => {
                    item.some(child => {
                        // console.log(child.data)
                        if (child.weekIndex === 4) {
                            child['data'] = [
                                {
                                    ac: '1',
                                    originName: "Average Player Load (Session)",
                                    slug: "average_player_load_session",
                                },
                                {
                                    ac: '0.8',
                                    originName: "Average Distance (Session)",
                                    slug: "average_distance_session"
                                },
                                {
                                    ac: '0.5',
                                    originName: "Total Duration",
                                    slug: "total_duration"
                                },
                                {
                                    ac: '1.4',
                                    originName: "急起急停次数",
                                    slug: "急起急停次数"
                                },
                                {
                                    ac: '2',
                                    originName: "&gt;19 km/h 距离",
                                    slug:"&gt;19_km/h_距离"
                                }
                            ]
                        }
                    })
                    return {
                        notEmpty: item.some(child => child.data),
                        dataArr: item,
                    }
                })

                console.log(state.weekData)

                proxy.$nextTick(() => {
                    renderEcharts()
                })
            })
        }
        const renderEcharts = async () => {
            if (!state.GPSData.length) {
                return
            }
            const arr = [...state.colors]
            arr.reverse();

            await proxy.$nextTick()
            //data为复选框，即用户想查看的数据类型
            state.weekData.forEach((item, index) => {
                if (!item.notEmpty) return;

                let dom = document.getElementById('bar-item' + index);
                let myChart = proxy.$echarts.init(dom);
                let option = null;
                let W = (state.filterArr.length * (state.weekLength * 2) * 11);
                console.log(W)
                if (dom.offsetWidth < W) {
                    dom.style.width = (parseInt(W)) + 'px';
                }
                myChart.resize();

                let series = [];
                let barData = [];//根据所选复选框，返回bar所需要的数据
                let dateArr = [];

                state.filterArr.forEach((filterArr, index) => {
                    barData.push({
                        name: filterArr.displayName,
                        data: []
                    })
                    dateArr = [];

                    item.dataArr.forEach((dataItem) => {
                        if (!((state.nowWeek - state.weekLength) <= dataItem.weekIndex)) return;

                        dateArr.push(dataItem.indexShow)
                        if (dataItem.data) {
                            const obj = dataItem.data.find(slugItem => slugItem.slug === filterArr.slug)
                            barData[index].data.push(obj ? Number(obj.ac).toFixed(filterArr.decimal) : '')
                        } else {
                            barData[index]['data'].push('')
                        }
                    })
                })

                // console.log(dateArr);
                // console.log(barData);

                //遍历所得到的数据，分别创建不同的bar->series
                barData.forEach((itemChild) => {
                    series.push(
                        {
                            type: 'bar',
                            name: state.playerName,
                            barGap: .5,
                            itemStyle: {
                                // normal: {
                                borderRadius: [10, 10, 10, 10],
                                color: (params) => {
                                    let index_color = params.value;
                                    if (index_color >= 0 && index_color < 0.8) {
                                        return '#FF5630'
                                    } else if (index_color >= 0.8 && index_color < 1.3) {
                                        return '#00D190'
                                    } else if (index_color >= 1.3 && index_color < 1.5) {
                                        return '#FFC700'
                                    } else if (index_color > 1.5) {
                                        return '#FF5630'
                                    } else {
                                        return '#FF5630'
                                    }
                                }
                                // }
                            },
                            data: itemChild.data.map(item => ({value: Number(item) > 0 ? item : ''})),
                            barWidth: 16,
                            label: {
                                show: true,
                                rotate: '90',
                                position: 'top',
                                align: 'left',
                                verticalAlign: 'middle',
                                distance: '5',
                                formatter: function (params) {
                                    let index_color = params.value;
                                    if (index_color >= 0 && index_color < 0.8) {
                                        // color = '#FF5630'
                                        return ['{b|' + params.value + '}', '{a|' + itemChild.name + '}'].join('  ');
                                    } else if (index_color >= 0.8 && index_color < 1.3) {
                                        // color = '#00D190'
                                        return ['{c|' + params.value + '}', '{a|' + itemChild.name + '}'].join('  ');
                                    } else if (index_color >= 1.3 && index_color < 1.5) {
                                        // color = '#FFC700'
                                        return ['{d|' + params.value + '}', '{a|' + itemChild.name + '}'].join('  ');
                                    } else if (index_color > 1.5) {
                                        // color = '#FF5630'
                                        return ['{b|' + params.value + '}', '{a|' + itemChild.name + '}'].join('  ');
                                    } else {
                                        // color = '#FF5630'
                                        return ['{b|' + params.value + '}', '{a|' + itemChild.name + '}'].join('  ');
                                    }
                                },
                                rich: {
                                    a: {
                                        color: '#aaaaaa',
                                        fontSize: '12',
                                        fontFamily: 'PingFangMedium',
                                    },
                                    b: {
                                        fontSize: '12',
                                        fontFamily: 'SysFontM',
                                        color: '#E55045'
                                    },
                                    c: {
                                        fontSize: '12',
                                        fontFamily: 'SysFontM',
                                        color: '#85C934'
                                    },
                                    d: {
                                        fontSize: '12',
                                        fontFamily: 'SysFontM',
                                        color: '#FFB23E'
                                    }
                                },
                            },
                            markLine: {
                                symbol: ['none', 'none'],//去掉箭头
                                itemStyle: {
                                    // normal: {
                                    lineStyle: {
                                        type: 'solid',
                                        width: 1.5,
                                        color: '#F34E4E'
                                    },
                                    // },
                                    label: {
                                        show: true,
                                        position: 'middle'
                                    }
                                },
                                data: [
                                    {
                                        yAxis: 0.8,
                                        lineStyle: {
                                            type: 'solid',
                                            color: '#F34E4E'
                                        }
                                    },
                                    {
                                        yAxis: 1.3,
                                        lineStyle: {
                                            type: 'solid',
                                            color: '#F3C74E'
                                        }
                                    },
                                    {
                                        yAxis: 1.5,
                                        lineStyle: {
                                            type: 'solid',
                                            color: '#F34E4E'
                                        }
                                    },
                                ]
                            },
                        },
                    )
                })

                option = {
                    tooltip: {
                        trigger: "axis",
                        backgroundColor: "#3A3A3A",
                        borderColor: "#3A3A3A",
                        padding: [10, 10],
                        formatter: (params) => {
                            let html = '';
                            params.map(paramsItem => {
                                let background = '';
                                let index_color = paramsItem.value;
                                if (index_color >= 0 && index_color < 0.8) {
                                    background = '#FF5630'
                                } else if (index_color >= 0.8 && index_color < 1.3) {
                                    background = '#00D190'
                                } else if (index_color >= 1.3 && index_color < 1.5) {
                                    background = '#FFC700'
                                } else if (index_color > 1.5) {
                                    background = '#FF5630'
                                } else {
                                    background = '#FF5630'
                                }

                                let value = paramsItem.value ? paramsItem.value : '暂无数据'
                                html += '<p style="font-size: 12px;margin-bottom: 10px">' +
                                    '<span style="display: inline-block;width: 8px;height: 8px;border-radius: 50%;margin-right: 10px;background: ' + background + '"></span>' +
                                    '<span style="color: #fff;margin-right: 10px;font-family: AkrobatMedium;">' + value + '</span>' +
                                    '<span style="color: rgba(255, 255, 255, 0.6);font-family: AkrobatRegular;">' + barData[paramsItem.seriesIndex].name + '</span></p>'
                            })
                            html += '<p style="font-size: 14px;margin-bottom: 5px;color: rgba(255, 255, 255, 0.6);">' + params[0].seriesName + '</p>'
                            return html;
                        },
                    },
                    grid: {
                        top: '80',
                        left: '30',
                        right: '30',
                        bottom: '15%',
                        containLabel: true,
                    },
                    xAxis: [
                        {
                            show: true,
                            type: "category",
                            data: dateArr,
                            axisLabel: {
                                //坐标轴刻度标签的相关设置。
                                show: true,
                                interval: 0, // 强制显示所有x轴name
                                textStyle: {
                                    color: '#B7B7BF',
                                    fontSize: 14
                                },
                                margin: 10,
                            },
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: false,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            show: true,
                            min: 0,
                            max: function (value) {
                                return Math.ceil(value.max + 1);
                            },
                            splitLine: {
                                show: false
                            }
                        }
                    ],
                    series: series
                };

                if (option && typeof option === "object") {
                    myChart.setOption(option, true);
                }

            })

        }
        if (state.pageType === 'player') {
            const params = {
                userId: route.query.id,
                userType: 'player'
            }
            proxy.$server.getUserTeams(params)
                .then(res => {
                    state.userTeamList = res.data;
                    changeFn(state.userTeamList[0])
                })
        } else {
            getFilter();
        }

        const changeFn = (item) => {
            state.teamId = item;
            store.commit('setTeamData', item)
            getFilter();
        }

        return {
            ...toRefs(state),

            changeFn
        }
    }
}
</script>

<style scoped lang="scss">
.barBox {
    height: 680px;
    width: 100%;

    .title {
        //position: relative;
        height: 80px;

        .label {
            position: absolute;
            right: 50px;
            top: 156px;
            font-size: 22px;
            font-family: akrobatRegular;
            color: rgba(255, 255, 255, .8);

            li {
                float: left;
                margin-right: 14px;

                i {
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    margin-right: 6px;
                    border-radius: 6px;
                }

                span {
                    font-family: akrobatRegular;
                }
            }
        }
    }

    .bar-item {
        height: calc(100% - 80px);
        width: 100%;
    }
}
</style>